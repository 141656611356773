import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper/Paper';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
	labelRoot: {
		color: '#b9b9b9'
	},
	selectRoot: {
		color: '#000',
		border: 0
	},
	selectBase: {
		padding: '.6875rem 3.5rem .6875rem 1.125rem',
		borderRadius: '1px',
		'&:focus': {
			borderColor: '#0079c1',
			backgroundColor: '#fff'
		}
	},
	selectEmpty: {
		border: '2px solid #b9b9b9',
	},
	selectValid: {
		border: '2px solid #434343',
	},
	selectError: {
		border: '2px solid #ee3524',
	},
	icon: {
		marginRight: '10px'
	},
	menuItemRoot: {
		paddingRight: '3rem',
		paddingLeft: '1.25rem',
		color: '#9f9f9f',
		'&:hover': {
			color: '#000',
			backgroundColor: '#F8F8F8'
		}
	},
	menuItemSelected: {
		color: '#000'
	},
	errorMessage: {
		marginTop: 2,
		color: '#ee3524'
	},
	// closeButton: {
	// 	position: "absolute",
	// 	bottom: theme.spacing.unit * 100,
	// 	right: theme.spacing.unit * 55
	// },
});

const MerloSelect = props => {
	const { classes, name, label, onChange, options, value, error, required, renderValue, onOpen, onClose, showCloseButton, multiple, showNoneAndAll } = props;

	return (
		<React.Fragment>
			{/* {
				showCloseButton &&
				(
					<IconButton className={classes.closeButton} aria-label="close">
						<CloseIcon fontSize="large" />
					</IconButton>
				)
			} */}
			<FormControl fullWidth>

				<InputLabel
					shrink
					required={required}
					htmlFor={name}
					classes={{
						root: classes.labelRoot
					}}
					error={Boolean(error)}
				>
					{label}
				</InputLabel>

				<Select
					multiple={multiple}
					required
					disableUnderline
					value={value}
					onChange={onChange}
					renderValue={renderValue}
					inputProps={{
						name,
						id: name,
					}}
					onOpen={onOpen}
					classes={{
						root: classes.selectRoot,
						select: error ?
							`${classes.selectBase} ${classes.selectError}`
							:
							value.length > 0 ? `${classes.selectValid} ${classes.selectBase}` : `${classes.selectEmpty} ${classes.selectBase}`
						,
						icon: classes.icon,
					}}
					onClose={onClose}
				>
					{
						options.map(option => {
							return (
								<MenuItem
									classes={{
										root: classes.menuItemRoot,
										selected: classes.menuItemSelected
									}}
									key={option.value}
									value={option.value}>
									<Checkbox
										checked={value.indexOf(option.value) > -1}
										color="primary"
									/>
									{option.label}

								</MenuItem>
							);
						})
					}
				</Select>
				{error && (
					<FormHelperText
						classes={{
							root: classes.errorMessage
						}}
					>
						{error}
					</FormHelperText>
				)}
			</FormControl>
		</React.Fragment>
	)
};

MerloSelect.propTypes = {
	classes: PropTypes.object,
	onChange: PropTypes.func,
	name: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]),
	renderValue: PropTypes.func,
	onOpen: PropTypes.func,
	menuProps: PropTypes.object,
	multiple: PropTypes.bool,
	showNoneAndAll: PropTypes.bool,
};

MerloSelect.defaultProps = {
	value: '',
	options: [],
	renderValue: null,
	onOpen: null,
	menuProps: null,
	multiple: true,
	showNoneAndAll: true
}

export default withStyles(styles)(MerloSelect);
