import axios from 'axios';
import decode from 'jwt-decode';

export const login = async (email, password, returnUrl) => {
	await redirectToProdIfUnauthorised();
	const isProd = process.env.NODE_ENV === 'production';

	const returnUrlWhitelist = isProd ? window.__env__.RETURNURL_WHITELIST : process.env.REACT_APP_RETURNURL_WHITELIST;

	try {
		// check for valid return url
		if (returnUrlWhitelist.indexOf(returnUrl) > -1) {
			const result = await axios.post('/v1/auth/login', {
				email, password
			});


			const { data } = result.data;
			// check that user has the required role
			const decoded = await decode(data.idToken);
			const { accessRoles } = decoded;

			if (accessRoles.indexOf('customer') > -1) {
				multipassLogin(email, password, '/')
			} else {
				// valid return url
				window.location.href = `${returnUrl}?idToken=${data.idToken}&refreshToken=${data.refreshToken}&expiresIn=${data.expiresIn}`;
			}
		}
	} catch (error) {
		console.log('login error', error.response.data);
		throw error.response.data;
	}
}

export const multipassLogin = async (email, password, redirect) => {
	await redirectToProdIfUnauthorised();
	try {
		const result = await axios.post(`/v1/auth/login/multipass`, {
			email,
			password,
			redirect
		});

		const { data } = result.data;
		if ('returnUrl' in data) {
			const { returnUrl } = data;
			window.location.href = returnUrl;
		}
	} catch (error) {
		// update the frontend error state with the api error
		throw error.response.data;
	}
};

export const registerUser = async user => {
	await redirectToProdIfUnauthorised();
	const { fom } = user;

	let payload = { ...user };
	if (fom === '') {
		delete payload.fom;
	}

	try {
		const result = await axios.post(`/v1/auth/register`, payload);
		return result.data;
	} catch (error) {
		return error.response.data;
	}
};

export const requestResetPassword = async email => {
	await redirectToProdIfUnauthorised();
	try {
		const result = await axios.post(`/v1/auth/request/password/reset`, {
			email
		});
		console.info('DEBUG: result', result);


		return result;
	} catch (error) {
		throw error.response.data;
	}
};

export const resetPassword = async (data) => {
	await redirectToProdIfUnauthorised();
	try {
		const result = await axios.post(`/v1/auth/password/reset`, data);

		return result;
	} catch (error) {
		throw error;
	}
};

// helper method
const formUrlEncoded = parts => Object.keys(parts).reduce((acc, cur) => {
	acc += `&${cur}=${encodeURIComponent(parts[cur])}`;
	return acc;
}, '');


const redirectToProdIfUnauthorised = async () => {
	const isProd = process.env.NODE_ENV === 'production';
	if (isProd) {
		//bail if production, don't even try anything.
		return true;
	}

	const result = await axios.get('/v1/version');

	if (!isProd && typeof (result) === 'undefined') {

		throw { errorCode: 401, message: "You are not authorised to access this testing site. The live site address is: https://auth.merlo.cloud/" }
		// This means there is no connection available to the API, so we must not be in the IP allow list.
		window.location.href = `https://auth.merlo.cloud/`;
		return true;
	}


}
