import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TextInput from './elements/TextInput';
import Button from './elements/Button';

const styles = theme => ({
  containerMaxWidth: {
    maxWidth: 720,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10
    },
  },
  backToLogin: {
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    marginBottom: 20,
  },
  link: {
    textDecoration: 'none',
    color: '#39B5FF',
  },
  paper: {
    maxWidth: 720,
    padding: '50px 20px 35px 20px',
    borderRadius: 0,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: '50px 92px 35px 92px'
    }
  },
  formTitle: {
    fontSize: 30,
    fontFamily: 'CoreCircus-Reg',
    marginBottom: 20,
  },
  descriptionText: {
    color: '#5A5A5A',
    marginBottom: 4
  },
  generalErrorText: {
		color: 'red',
		fontSize: 12,
	},
  hint: {
    fontSize: '0.75em',
  }
});

const NewPasswordForm = props => {
  const { classes, password, confirmPassword, onSubmit, onChange, errors, generalError, loading } = props;

  return (
    <Container maxWidth="md" classes={{ maxWidthMd: classes.containerMaxWidth }}>
      <div className={classes.backToLogin}>
        <Typography style={{ fontFamily: 'UniversLTStd-Bold' }}>
          <Link to="/" className={classes.link}>
            Back to login
          </Link>
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <Typography variant='h6' align='center' className={classes.formTitle}>Set new password</Typography>
        <Grid container spacing={2}>
        {
						generalError &&
						<Grid item xs={12}>
							<Typography align="center" className={classes.generalErrorText}>{generalError}</Typography>
						</Grid>
				}
          <Grid item xs={12}>
            <Typography className={classes.descriptionText}>
              Enter your password below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput required type="password" name="password" 
              error={errors.password} 
              onChange={onChange} 
              value={password} label="New password" 
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" variant="subtitle1" className={classes.hint}>
              Password length must be between 6 and 20 and must have at least 1 lower case character, 1 upper case character, 1 number and 1 symbol
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput required type="password" name="confirm" 
              error={errors.confirmPassword} 
              onChange={onChange} 
              value={confirmPassword} 
              label="Confirm password" 
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button style={{ marginTop: 24 }} onClick={onSubmit} loading={loading}>Set password</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

NewPasswordForm.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(NewPasswordForm);
