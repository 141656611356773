import React from 'react';
import PropTypes from 'prop-types';

import bg from '../static/images/bg.png';
import Logo from '../static/images/logo-cftb-white-2024.png';

const styles = {
	background: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: 580,
		width: '100%',
		backgroundImage: `url(${bg})`,
		backgroundSize: 'cover'
	},
	logo: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		margin: '40px 0px'
	}
};

const Content = props => {
	const { children } = props;
	const isProd = process.env.NODE_ENV === 'production';
	const storeLink = isProd ? window.__env__.STORE_LINK : process.env.REACT_APP_STORE_LINK;

	return (
		<div style={styles.background}>
			<div style={styles.logo}>
				<a href={storeLink}>
					<img src={Logo} alt="Merlo Logo" style={{ height: 120 }} />
				</a>
			</div>
			{children}
		</div>
	)
};

Content.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

export default Content;
