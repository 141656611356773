import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextInput from './elements/TextInput';
import Checkbox from './elements/Checkbox';
import Button from './elements/Button';

const styles = theme => ({
	containerMaxWidth: {
		maxWidth: 720,
		padding: 0,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 10,
			paddingRight: 10
		},
	},
	signupMsg: {
		textTransform: 'uppercase',
		color: '#fff',
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-end'
		},
		marginBottom: 20,
		userSelect: 'none'
	},
	signUpText: {
		fontFamily: 'UniversLTStd-Bold',
		color: '#39B5FF',
		userSelect: 'text'
	},
	link: {
		textDecoration: 'none',
		width: 'fit-content'
	},
	paper: {
		maxWidth: 720,
		padding: '50px 20px 35px 20px',
		borderRadius: 0,
		margin: '0 auto',
		[theme.breakpoints.up('sm')]: {
			padding: '50px 92px 35px 92px'
		}
	},
	formTitle: {
		fontSize: 30,
		fontFamily: 'CoreCircus-Reg',
		marginBottom: 20,
	},
	resetPasswordText: {
		fontFamily: 'UniversLTStd-Bold',
		display: 'inline',
		fontSize: 14,
		color: '#0079C1',
		marginTop: -6,
		marginBottom: 18
	},
	resetPasswordContainer: {
		marginTop: -6,
		marginBottom: 9
	},
	inputPadding: {
		padding: '12px 8px 8px 8px !important'
	},
	checkboxContainer: {
		marginBottom: 15
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	generalErrorText: {
		color: 'red',
		fontSize: 12,
	}
});

const LoginForm = props => {
	const { classes, onChange, onSubmit, errors, email, password, remember, loading, generalError, onKeyPress } = props;

	return (
		<Container maxWidth="md" classes={{ maxWidthMd: classes.containerMaxWidth }}>
			<div className={classes.signupMsg}>
				<Typography style={{ fontFamily: 'UniversLTStd-Bold' }}>
					{`Don't have an account? `}
					<Link to="/signup" className={classes.link}>
						<span className={classes.signUpText}>Sign Up Here</span>
					</Link>
				</Typography>
			</div>
			<Paper className={classes.paper}>
				<Typography variant='h6' align='center' className={classes.formTitle}>LOGIN</Typography>
				<Grid container spacing={2}>
					{
						generalError &&
						<Grid item xs={12}>
							<Typography align="center" className={classes.generalErrorText}>{generalError}</Typography>
						</Grid>
					}
					<Grid item xs={12}>
						<TextInput
							required
							name='email'
							label='Email address'
							error={errors.email}
							value={email}
							onChange={onChange}
							onKeyPress={onKeyPress}
							type='email'
						/>
					</Grid>
					<Grid className={classes.inputPadding} item xs={12}>
						<TextInput
							required
							type='password'
							name="password"
							label="Password"
							error={errors.password}
							value={password}
							onChange={onChange}
							onKeyPress={onKeyPress}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.resetPasswordContainer} justify="flex-end">
							<Link to="/reset" className={classes.link}>
								<Typography align="right" className={classes.resetPasswordText}>RESET PASSWORD</Typography>
							</Link>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.checkboxContainer} justify="center">
							<Checkbox
								checked={remember}
								name="remember"
								label="Remember me"
								onChange={onChange}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify="center">
							<Button
								onClick={onSubmit}
								loading={loading}
							>
								Login
							</Button>

						</Grid>
					</Grid>
				</Grid>
			</Paper>

		</Container>
	);
}

LoginForm.propTypes = {
	classes: PropTypes.object,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	email: PropTypes.string,
	password: PropTypes.string,
	remember: PropTypes.bool,
	errors: PropTypes.object,
	generalError: PropTypes.string,
	onKeyPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginForm);
