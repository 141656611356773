import 'dotenv/config';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import './app.scss';
import * as serviceWorker from './serviceWorker';


import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-P6LCMF2'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
