import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import * as authActions from '../actions/authActions';

import LoginForm from '../components/LoginForm';
import * as validation from '../utils/validationHelpers';

import { parseUrlErrorCode } from '../utils/parseUrlError';
// any login request that lands here needs to have a redirect url
// if it doesnt - assume that its getting redirected to the shop front via multipass
// if it does -- it needs to be on the whitelist of redirect urls

// regular login -> multipass
// social login -> social multipass
// regular login + redurect -> normal auth + redirect (whitelist url)

// can users log into the staff portal with social? I would say not (confirm)

class LoginPage extends Component {
	state = {
		email: '',
		password: '',
		remember: false,
		errors: {},
		generalError: null,
		redirect: '/',
		returnUrl: '',
		loading: false,
		fromStaffPortal: false,
	};

	componentDidMount() {
		if (this.props.location.search) {
			const isProd = process.env.NODE_ENV === 'production';
			const returnUrlWhitelist = isProd ? window.__env__.RETURNURL_WHITELIST : process.env.REACT_APP_RETURNURL_WHITELIST;
			const values = queryString.parse(this.props.location.search);
			const errors = parseUrlErrorCode(this.props.location);

			const { redirect, returnUrl } = values;
			let fromStaffPortal = false;
			// check returnUrl for whitelist 
			if (returnUrlWhitelist.indexOf(returnUrl) > -1) {
				fromStaffPortal = true;
			}

			this.setState({
				generalError: errors,
				redirect: redirect ? redirect : this.state.redirect,
				returnUrl: returnUrl || '',
				fromStaffPortal,
			})
		}
	}

	onChange = e => {
		const { name, type, value, checked } = e.target;
		const isCheckbox = type === "checkbox";

		this.setState({
			[name]: isCheckbox ? checked : value,
		});
	};

	onSubmit = async () => {
		const { email, password } = this.state;

		const newErrors = validation.validateLogin({ email, password });

		if (!newErrors) this.login();

		this.setState({ errors: newErrors || {} });
	};

	onKeyPress = event => {
		if (event.key === 'Enter') {
			this.onSubmit();
		}
	}

	login = async () => {
		const { email, password, redirect, fromStaffPortal, returnUrl } = this.state;
		this.setState({ loading: true });

		// check the returnurl param to route the appropriate call
		// Do we need to pass the remember me param?
		try {
			if (fromStaffPortal) {
				await authActions.login(email, password, returnUrl);
			} else {
				await authActions.multipassLogin(email, password, redirect);
			}
		} catch (error) {
			console.log(error);



			const dbError = error.errorCode === 'DATABASE_NO_DATA';
			const invalid = 'Email or password incorrect. Please try again or click the link below to change your password.';
			this.setState({
				loading: false,
				generalError: dbError ? invalid : error.message
			})
		}

		this.setState({ loading: false });
	}

	render() {
		const { email, password, remember, errors, loading, generalError } = this.state;
		return (
			<LoginForm
				email={email}
				password={password}
				remember={remember}
				errors={errors}
				onChange={this.onChange}
				onSubmit={this.onSubmit}
				loading={loading}
				generalError={generalError}
				onKeyPress={this.onKeyPress}
			/>
		)
	}
}

export default withRouter(LoginPage);
