import React from 'react';
import PropTypes from 'prop-types';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { returnFirstError } from '../../utils/validationHelpers';
import moment from 'moment';

const styles = {
    labelRoot: {
		color: '#b9b9b9'
	},
  base: {
    '& input': {
      padding: '.6875rem 3.5rem .6875rem 1rem',
    }
  },
  rootEmpty: {
    '& .MuiInputBase-root': {
      border: '2px solid #b9b9b9',
      width: '100%'
    }
  },
  rootValue: {
    '& .MuiInputBase-root': {
      border: '2px solid #434343',
      width: '100%'
    }
  },
  rootError: {
    '& .MuiInputBase-root': {
      border: '2px solid #ee3524',
      width: '100%'
    }
  },
  errorMessage:{
    marginTop: 2,
    color: '#ee3524'
  }
}

const Datepicker = props => {
  const { classes, onChange, name, label, style, value, error, maxDate, maxDateMessage, minDate, minDateMessage } = props;
  const firstError = returnFirstError(error);

  const hasError = Boolean(error);

  const errorStyle = `${classes.base} ${classes.rootError}`;
  const noErrorStyle = value ? `${classes.base} ${classes.rootValue}` : `${classes.base} ${classes.rootEmpty}`;

  return (
    <React.Fragment>
        <InputLabel
            shrink
            required
            error={hasError}
            classes={{
                root: classes.labelRoot
            }}
        >
            Birthday
        </InputLabel>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                className={error ? errorStyle : noErrorStyle}
                fullWidth
                name={name}
                label={label}
                value={value}
                format='DD/MM/YYYY'
                required={true}
                onChange={onChange}
                style={style}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    disableUnderline: true
                }}
                minDate={minDate}
                minDateMessage={minDateMessage}
                maxDate={maxDate}
                maxDateMessage={maxDateMessage}
            />
        </MuiPickersUtilsProvider>
        {error && (
            <FormHelperText 
            classes={{
                root: classes.errorMessage
            }}
            >
            {firstError}
            </FormHelperText>
        )}
    </React.Fragment>
  )
};

Datepicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.array,
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date)
  ]),
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date)
  ]),
  minDateMessage: PropTypes.string
};

export default withStyles(styles)(Datepicker);
