import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Button from './elements/Button';

const styles = theme => ({
	containerMaxWidth: {
		maxWidth: 720,
		padding: 0,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 10,
			paddingRight: 10
		},
	},
	paper: {
		maxWidth: 720,
		padding: '50px 20px 35px 20px',
		borderRadius: 0,
		margin: '0 auto',
		[theme.breakpoints.up('sm')]: {
			padding: '50px 92px 35px 92px'
		}
	},
	formTitle: {
		fontSize: 30,
		fontFamily: 'CoreCircus-Reg',
		marginBottom: 20,
	},
	descriptionText: {
		color: '#5A5A5A',
		marginBottom: 4
	}
});

const ConfirmationMessage = props => {
	const { classes, message, title } = props;
	console.log(message);
	return (
		<Container maxWidth="md" classes={{ maxWidthMd: classes.containerMaxWidth }}>
			<Paper className={classes.paper}>
				<Typography variant='h6' align='center' className={classes.formTitle}>{title}</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography align='center' className={classes.descriptionText}>
							{message}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify="center">
							<Link to="/" style={{ textDecoration: 'none' }}>
								<Button style={{ marginTop: 24 }} loading={false} >Back to log in</Button>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	)
}

ConfirmationMessage.propTypes = {
	classes: PropTypes.object,
	message: PropTypes.string,
	title: PropTypes.string
};

export default withStyles(styles)(ConfirmationMessage);
