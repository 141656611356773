import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TextInput from './elements/TextInput';
import Button from './elements/Button';

const styles = theme => ({
  containerMaxWidth: {
    maxWidth: 720,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10
    },
  },
  backToLogin: {
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    marginBottom: 20,
  },
  link: {
    textDecoration: 'none',
    color: '#39B5FF',
  },
  paper: {
    maxWidth: 720,
    padding: '50px 20px 35px 20px',
    borderRadius: 0,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: '50px 92px 35px 92px'
    }
  },
  formTitle: {
    fontSize: 30,
    fontFamily: 'CoreCircus-Reg',
    marginBottom: 20,
  },
  descriptionText: {
    color: '#5A5A5A',
    marginBottom: 4
  }
});

const ResetForm = props => {
  const { classes, onChange, onSubmit, email, error, loading } = props;

  return (
    <Container maxWidth="md" classes={{ maxWidthMd: classes.containerMaxWidth }}>
      <div className={classes.backToLogin}>
        <Typography style={{ fontFamily: 'UniversLTStd-Bold' }}>
          <Link to="/" className={classes.link}>
            Back to login
          </Link>
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <Typography variant='h6' align='center' className={classes.formTitle}>Reset Password</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.descriptionText}>
              Enter your email below to reset your password. You will receive a link in your email with instructions to create a new password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput required type="email" name="email" label="Email address" value={email} onChange={onChange} error={error} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button style={{ marginTop: 24 }} loading={loading} onClick={onSubmit}>Reset Password</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

ResetForm.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  email: PropTypes.string,
};

export default withStyles(styles)(ResetForm);
