import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

import { returnFirstError } from '../../utils/validationHelpers';
import { isArray } from 'util';


const styles = {
  labelRoot: {
    color: '#b9b9b9 !important'
  },
  inputRootEmpty: {
    border: '2px solid #b9b9b9',
    borderRadius: '1px'
  },
  inputRootValid: {
    border: '2px solid #434343',
    borderRadius: '1px'
  },
  inputFocused: {
    border: '2px solid #0079c1',
    borderRadius: '1px'
  },
  inputError: {
    border: '2px solid #ee3524',
    borderRadius: '1px'
  },
  input: {
    padding: '.6875rem 3.5rem .6875rem 1rem'
  },
  errorMessage: {
    color: '#ee3524 !important'
  }
};

const TextInput = props => {
  const { classes, type, placeholder, label, name, required, value, error, onChange, max, ...rest } = props;
  let hasError = false;

  if ((isArray(error) && error.length > 0) || (!isArray(error) && Boolean(error))) {
    hasError = true;
  }

  const errorMsg = returnFirstError(error);

  // console.log(hasError, error);

  return (
    <TextField
      fullWidth
      type={type}
      name={name}
      id={name}
      label={label}
      placeholder={placeholder}
      required={required}
      value={value}
      error={hasError}
      onChange={onChange}
      helperText={hasError ? errorMsg : ''}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
          error: classes.errorMessage
        }
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: value ? classes.inputRootValid : classes.inputRootEmpty,
          input: classes.input,
          focused: classes.inputFocused,
          error: classes.inputError
        },
        inputProps: {
          maxLength: max
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.errorMessage
        }
      }}
      {...rest}
    />
  )
};

TextInput.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.array,
  max: PropTypes.number
};

TextInput.defaultProps = {
  type: 'text',
};

export default withStyles(styles)(TextInput);
