import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import portalTheme from '../constants/theme';

import Content from '../components/Content';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import ResetPasswordPage from './ResetPasswordPage';
// import NewPasswordPage from './NewPasswordPage';
// import VerifyEmail from './VerifyEmail';
import AuthPage from './Auth';
import envConfig from '../utils/envConfig';
import axiosConfig from '../utils/axiosConfig';


class App extends Component {
	state = {
		initialized: false
	};

	async componentDidMount() {
		await envConfig(axiosConfig);
		this.setState({ initialized: true });
	}


	render() {
		const { initialized } = this.state;
		return (
			initialized &&
			<ThemeProvider theme={portalTheme}>
				<Content>
					<Router>
						<Switch>
							<Route path="/signup" component={SignupPage} />
							<Route path="/reset" component={ResetPasswordPage} />
							<Route path="/auth" component={AuthPage} />
							<Route path="/" component={LoginPage} />
						</Switch>
					</Router>
				</Content>
			</ThemeProvider>
		);
	}
}

App.propTypes = {
	// TO DO
};

export default App;