import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
      primary: {
        main: '#0079c1',
        light: '#39b5ff',
        dark: '#045d91'
      }
    },
});
