import axios from 'axios';

export default () => {
	// console.log(window.__env__);
	// console.log(process.env.NODE_ENV);
	const isProd = process.env.NODE_ENV === 'production';

	axios.defaults.baseURL = isProd ? window.__env__.BASE_URL : process.env.REACT_APP_BASE_URL;
    // console.log("TCL: axios.defaults.baseURL", axios.defaults.baseURL)
	axios.defaults.headers.common['Content-Type'] = 'applicaton/json';
}