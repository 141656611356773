import axios from 'axios';

export const getMerloStores = async () => {
  try {
    const result = await axios.get('/v1/locations/search?isMerloOwned=true')
    const { data } = result.data;
    // return data.map(location => location.title);
    return data.reduce((acc, loc) => {
      acc.push({ label: loc.title, value: loc.uuid })
      return acc;
    }, []);
  } catch (error) {
    console.log(error);
  }
};
