import axios from 'axios';

export default async callback => {
	const isProd = process.env.NODE_ENV === 'production';

	if (isProd) {
		// take query the internal api endpoint /config
		const result = await axios.get('/config');
		const { data } = result;

		// assign the variables to window.__env__
		window.__env__ = {
			BASE_URL: data.baseurl,
			FB_CLIENT_ID: data.fbClientId,
			FB_REDIRECT_URI: data.fbRedirectUri,
			GOOGLE_CLIENT_ID: data.googleClientId,
			GOOGLE_REDIRECT_URI: data.googleRedirectUri,
			APPLE_REDIRECT_URI: data.appleRedirectUri,
			APPLE_CLIENT_ID: data.appleClientId,
			RETURNURL_WHITELIST: data.returnUrlWhitelist,
			REACT_APP_PRIVACY_POLICY: data.privacyPolicy,
			REACT_APP_TERMS_AND_CONDITIONS: data.termsAndConditions,
			STORE_LINK: data.storeLink,
		};
	}
	callback();
};
