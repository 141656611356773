import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import VerifyEmail from './VerifyEmail';
import NewPasswordPage from './NewPasswordPage';

class Auth extends Component {
	state = {
		mode: ''
	};

	parseURL = async (props) => {
		const { location } = props;
		const params = new URLSearchParams(location.search);
		const mode = params.get('mode')
		this.setState({ mode });

	}

	componentDidMount() {
		this.parseURL(this.props)
	}


	render() {
		const { mode } = this.state;

		switch (mode) {
			case 'verifyEmail':
				return <VerifyEmail />;
			case 'resetPassword':
				return <NewPasswordPage />;
			default:
				return null;
		}
	}
}

Auth.propTypes = {

};


export default withRouter(Auth);