import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "./elements/Button";
import TextInput from "./elements/TextInput";
import Datepicker from "./elements/Datepicker";
import Checkbox from "./elements/Checkbox";
import Select from "./elements/Select";
import { List, ListItem, ListItemText } from "@material-ui/core";


const styles = theme => ({
  containerMaxWidth: {
    maxWidth: 720,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  loginMsg: {
    textTransform: "uppercase",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end"
    },
    marginBottom: 20,
    userSelect: "none"
  },
  link: {
    textDecoration: "none"
  },
  loginText: {
    fontFamily: "UniversLTStd-Bold",
    color: "#39B5FF",
    userSelect: "text"
  },
  topPaper: {
    maxWidth: 720,
    padding: "50px 20px 40px 20px",
    borderRadius: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "50px 92px 40px 92px"
    }
  },
  centerPaper: {
    maxWidth: 720,
    padding: "40px 20px 40px 20px",
    borderRadius: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "40px 92px 40px 92px"
    }
  },
  bottomPaper: {
    maxWidth: 720,
    padding: "40px 20px 45px 20px",
    borderRadius: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "40px 92px 45px 92px"
    },
    marginBottom: 130
  },
  formTitle: {
    fontSize: 30,
    fontFamily: "CoreCircus-Reg",
    marginBottom: 20
  },
  inputPadding: {
    padding: "12px 8px 8px 8px !important"
  },
  subtitle: {
    marginBottom: 20
  },
  informationText: {
    fontSize: 14,
    color: "#A0A0A0",
    marginTop: 15,
    marginBottom: 24
  },
  hint: {
    fontSize: '0.75em',
  }
});

const SignupForm = props => {
  const {
    classes,
    onChange,
    handleBirthday,
    onSubmit,
    errors,
    firstName,
    lastName,
    email,
    mobileNumber,
    birthday,
    postcode,
    password,
    confirm,
    privacyPolicy,
    agreeToTerms,
    loading,
    country
  } = props;
  
  const isProd = process.env.NODE_ENV === 'production';

  const [showCloseIcon, setShowCloseIcon] = React.useState(false);

  const onOpen = () => {
    setShowCloseIcon(!showCloseIcon)
  };

  const maxBirthday = () => {
    return new Date()
  }
  const minBirthday = () => {
    return new Date().setYear(new Date().getYear() - 100)
  }

  return (
    <Container
      maxWidth="md"
      classes={{ maxWidthMd: classes.containerMaxWidth }}
    >
      <div className={classes.loginMsg}>
        <Typography style={{ fontFamily: "UniversLTStd-Bold" }}>
          {`Already a friend of merlo? `}
          <Link to="/" className={classes.link}>
            <span className={classes.loginText}>Log in Here</span>
          </Link>
        </Typography>
      </div>
      <Paper className={classes.topPaper}>
        <Typography align="center" className={classes.formTitle}>
          SIGN UP
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              required
              name="firstName"
              label="First name"
              value={firstName}
              error={errors.firstName}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              required
              name="lastName"
              label="Last name"
              value={lastName}
              error={errors.lastName}
              onChange={onChange}
            />
          </Grid>
          <Grid className={classes.inputPadding} item xs={12}>
            <TextInput
              required
              name="email"
              label="Email address"
              value={email}
              error={errors.email}
              onChange={onChange}
							type='email'
              />
          </Grid>
          <Grid className={classes.inputPadding} item xs={12}>
              <Select
                name="country"
                label="Country"
                error={errors.country}
                options={[{ value: 'Australia', label: 'Australia' },  { value: 'New Zealand', label: 'New Zealand' }]}
                value={country}
                onChange={onChange}
                onOpen={onOpen}
                onClose={onOpen}
                renderValue={value => {
                  return value;
                }}
                showCloseButton={showCloseIcon}
                multiple={false}
                showNoneAndAll={false}
              />
          </Grid>
          <Grid className={classes.inputPadding} item xs={12}>
            <TextInput
              required
              name="mobileNumber"
              label="Mobile number"
              value={mobileNumber}
              error={errors.mobileNumber}
              onChange={onChange}
              max={13}
            />
          </Grid>
          <Grid className={classes.inputPadding} item xs={12}>
            <TextInput
              required
              name="postcode"
              label="Postcode"
              value={postcode}
              error={errors.postcode}
              onChange={onChange}
              max={4}
            />
          </Grid>
          <Grid className={classes.inputPadding} item xs={12}>
            <Datepicker
              name="birthday"
              value={birthday}
              onChange={handleBirthday}
              error={errors.birthday}
              maxDate={maxBirthday()}
              minDate={minBirthday()}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.bottomPaper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" className={classes.subtitle}>
              Create a password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              name="password"
              type="password"
              label="Password"
              value={password}
              error={errors.password}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" variant="subtitle1" className={classes.hint}>
              Password length must be between 6 and 20 and must have at least 1 lower case character, 1 upper case character, 1 number and 1 symbol
            </Typography>
          </Grid>
          <Grid className={classes.inputPadding} item xs={12}>
            <TextInput
              required
              name="confirm"
              type="password"
              label="Confirm password"
              value={confirm}
              error={errors.confirm}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" style={{ marginTop: 15 }}>
              <div style={{ maxWidth: 280, width: "fit-content" }}>
                <Checkbox
                  name="privacyPolicy"
                  label={<label>Agree to the <a href={isProd ? window.__env__.REACT_APP_PRIVACY_POLICY : process.env.REACT_APP_PRIVACY_POLICY} target='_blank'>privacy policy*</a></label>}
                  checked={privacyPolicy}
                  error={errors.privacyPolicy}
                  onChange={onChange}
                  />
                <Checkbox
                  name="agreeToTerms"
                  label={<label>Agree to the <a href={isProd ? window.__env__.REACT_APP_TERMS_AND_CONDITIONS : process.env.REACT_APP_TERMS_AND_CONDITIONS} target='_blank'>terms & conditions*</a></label>}
                  checked={agreeToTerms}
                  error={errors.agreeToTerms}
                  onChange={onChange}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button onClick={onSubmit} loading={loading}>
                Sign up
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

SignupForm.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(SignupForm);
