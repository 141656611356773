import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
	buttonRoot: {
		cursor: "pointer",
		lineHeight: 1,
		userSelect: "none",
		width: 220,
		height: 50,
		// padding: "13px 60px 11px 60px",
		border: "2px solid transparent",
		fontWeight: 400,
		color: "#fff",
		backgroundColor: "#0079c1",
		borderRadius: 0,
		textTransform: "uppercase",
		"&:hover": {
			backgroundColor: "#39b5ff"
		},
		"&:active": {
			backgroundColor: "#045d91"
		},
		"&:focus": {
			outline: 0,
			boxShadow: "0 0 0 0.2rem rgba(0,121,193,.25)"
		}
	},
	typeRoot: {
		fontFamily: '"CoreCircus-Reg","CoreCircus", sans-serif',
    marginTop: "0.1rem"
	},
	progress: {
		color: 'white'
	}
};

const MerloButton = props => {
	const { classes, children, onClick, loading, style } = props;

	return (
		<Button
			disableFocusRipple
			disableRipple
			onClick={onClick}
			style={style}
			classes={{
				root: classes.buttonRoot
			}}
			disabled={loading}
		>
			{
				loading ?
					<CircularProgress size={25} className={classes.progress} />
					:
					<Typography classes={{ root: classes.typeRoot }}>
						{children}
					</Typography>
			}
		</Button>
	);
};

MerloButton.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	loading: PropTypes.bool.isRequired,
	text: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object
};

export default withStyles(styles)(MerloButton);
