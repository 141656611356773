import moment from 'moment';
import validate from 'validate.js';

validate.extend(validate.validators.datetime, {
  parse: function(value, options) {
    return +moment.utc(value);
  },
  format: function(value, options) {
    const format = "YYYY-MM-DD";
    return moment.utc(value).format(format);
  }
});


export const login = {
  email: {
    email: {
      message: '^Please enter a valid email.'
    },
    presence: {
      message: '^You must enter your email.',
      allowEmpty: false
    }
  },
  password: {
    presence: {
      message: '^You must enter your password.',
      allowEmpty: false
    }
  }
};

export const signup = {
  firstName: {
    presence: {
      message: '^You must enter your first name.',
      allowEmpty: false
    },
    format: {
      pattern: '^[a-zA-Z ,.\'-]+$',
      flags: 'i',
      message: '^First name can only contain characters a-z, spaces, commas, hyphens and apostrophes'
    }
  },
  lastName: {
    presence: {
      message: '^You must enter your last name.',
      allowEmpty: false
    },
    format: {
      pattern: '^[a-zA-Z ,.\'-]+$',
      flags: 'i',
      message: '^Last name can only contain characters a-z, spaces, commas, hyphens and apostrophes'
    }
  },
  email: {
    email: {
      message: '^Please enter a valid email.',
      allowEmpty: false
    }
  },
  mobileNumber: {
    presence: {
      message: '^You must enter your mobile number.',
      allowEmpty: false
    }
  },
  birthday: {
    presence: {
      message: '^You must enter your birthday.',
      allowEmpty: false
    }
  },
  fom: {
    format: {
      pattern: '^(606004[0-9]{6}$|606004[0-9]{14}$)|(0107[0-9]{8}$|0107[0-9]{16}$)',
      flags: 'i',
      message: '^That card number is invalid, try again or leave the field blank to get a new card.'
    }
  },
  postcode: {
    presence: {
      message: '^You must enter your postcode.',
      allowEmpty: false
    },
    format: {
      pattern: "^[0-9]{4}",
      flags: "i",
      message: "^You must enter a valid postcode."
    }
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: "^Your password must be at least 6 characters."
    },
    format: {
      pattern: "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).{6,20})",
      message: "^Password length must be between 6 and 20 and must have at least 1 lower case character, 1 upper case character, 1 number and 1 symbol"
    }
  },
  confirm: {
    presence: {
      message: '^You must confirm your password.',
      allowEmpty: false
    },
    equality: {
      attribute: "password",
      message: '^Your passwords do not match.'
    }
  },
  privacyPolicy: {
    presence: {
      message: "^You must accept our privacy policy"
    },
    inclusion: {
      within: [true],
      message: "^You must accept our privacy policy"
    }
  },
  agreeToTerms: {
    presence: {
      message: "^You must agree to our Terms & Conditions"
    },
    inclusion: {
      within: [true],
      message: "^You must agree to our Terms & Conditions"
    }
  }
};

export const fom = {
  fom: {
    length: {
      minimum: 12,
      maximum: 20,
      tooShort: "^Your FOM number must be at least 8 digits.",
      tooLong: "^Your FOM number must be less than 20 digits."
    }
  }
};

export const email = {
  email: {
    email: {
      message: '^Please enter a valid email.',
      allowEmpty: false
    }
  }
};

export const password = {
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: "must be at least 6 characters"
    },
    format: {
      pattern: "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).{6,20})",
      message: "^Password length must be between 6 and 20 and must have at least 1 lower case character, 1 upper case character, 1 number and 1 symbol"
    }
  }
};

export const confirmPassword = {
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: "must be at least 6 characters"
    }
  },
  confirm: {
    presence: {
      message: '^You must confirm your password.',
      allowEmpty: false
    },
    equality: {
      attribute: "password",
      message: '^Your passwords do not match.'
    }
  }
};

