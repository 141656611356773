import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ConfirmationMessage from '../components/ConfirmationMessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const styles = theme => ({
	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	spinner: {
		color: '#1b3653'
	},
	paper: {
		maxWidth: 720,
		borderRadius: 0,
		margin: '0 auto',
		height: 500,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	loadingText: {
		fontFamily: 'UniversLTStd-Bold',
		marginBottom: 40,
	}

})


class VerifyEmail extends Component {
	state = {
		verified: false,
		title: '',
		message: '',
	}

	parseURL = async (props) => {
		const { location } = props;
		const params = new URLSearchParams(location.search);
		const mode = params.get('mode')

		const actionCode = params.get('oobCode');
		const continueUrl = params.get('continueUrl');
		const lang = params.get('lang') || 'en';
		// let result = false;

		try {
			const result = await axios.post('/v1/auth/verify', {
				mode,
				actionCode,
				continueUrl,
				lang,
			});

			const { status } = result;
			this.setState({
				verified: true,
				title: status === 200 ? 'Email has successfully been verified' : 'An error occurred',
				message: status === 200 ? 'Your account is being setup and will be ready to use in a couple of minutes, perfect time to get a coffee!' : 'There was a problem with the activation link, you may already be activated or this link has expired.',
			});

		} catch (error) {
			console.log("TCL: VerifyEmail -> parseURL -> error", error)
			this.setState({
				verified: true,
				title: 'An error occurred',
				message: 'There was a problem with the activation link, you may already be activated or this link has expired.',
			})
		}

	}

	async componentDidMount() {
		await this.parseURL(this.props)
	}


	render() {
		const { title, message, verified } = this.state;
		const { classes } = this.props;

		return (
			<React.Fragment>
				{
					verified ?
						<ConfirmationMessage
							message={message}
							title={title}
						/>
						:
						<Paper className={classes.paper}>
							<Typography className={classes.loadingText}>
								...Grinding beans...
							</Typography>
							<CircularProgress size={32} className={classes.spinner} />
						</Paper>
				}
			</React.Fragment>
		)
	}
}

VerifyEmail.propTypes = {

};


export default withRouter(withStyles(styles)(VerifyEmail));