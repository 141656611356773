import React, { Component } from 'react';

import * as authActions from '../actions/authActions';
import { getMerloStores } from '../actions/locationActions';
import * as validation from '../utils/validationHelpers';

import SignupForm from '../components/SignupForm';
import ConfirmationMessage from '../components/ConfirmationMessage';

import { parsePhoneNumber } from 'awesome-phonenumber';
import moment from 'moment';

const countryCodes = {
	"australia": 'AU',
	"new zealand": 'NZ'
}
class SignupPage extends Component {
	state = {
		showForm: true,
		response: '',
		firstName: '',
		lastName: '',
		email: '',
		mobileNumber: '',
		birthday: null,
		postcode: '',
		fom: '',
		password: '',
		confirm: '',
		privacyPolicy: false,
		agreeToTerms: false,
		errors: {},
		loading: false,
		country: 'Australia'
	};


	onChange = e => {
		const { name, type, value, checked } = e.target;
		const isCheckbox = type === "checkbox";

		const isMobile = name === 'mobileNumber';
		if (isMobile) {
			const parsedNumber = parsePhoneNumber(value, { regionCode: countryCodes[this.state.country.toLocaleLowerCase()] });
			const mobileNumberFormatted = parsedNumber.valid ? parsedNumber.number.international.replace(/[^\d+]+/g, '') : value;
			const isValid = (parsedNumber.valid && mobileNumberFormatted.match(/^\+(642|614)/));

			if (isValid) {
				this.setState({ [name]: mobileNumberFormatted }, () => {
					this.removeError('mobileNumber');
				});
			} else {
				this.setState({ [name]: mobileNumberFormatted })
			}
		} else {
			this.setState({ [name]: isCheckbox ? checked : value });
		}

		this.validateInput(name, isCheckbox ? checked : value);

		if (name === 'country') {
			// if country changes wipe this country specific data
			this.setState({
				'mobileNumber': '',
				'postcode': ''
			}, () => {
				this.removeError('postcode');
				this.removeError('mobileNumber');
			})
		}
	};

	handleBirthday = date => {
		this.setState({
			birthday: moment(date).startOf('day').utc(true).toISOString()
		}, () => {
			this.validateInput('birthday', date);
		});
	};

	validateInput = (name, value) => {
		let error = null;
		switch (name) {
			case 'email':
				error = validation.validateEmail(value);
				console.info('DEBUG: validateInput -> error', error);
				if (error) {
					this.addSingleError(error);
				} else this.removeError('email');
				break;
			case 'password':
				error = validation.validatePassword(value);
				if (error) {
					this.addSingleError(error);
				} else this.removeError('password');
				break;
			case 'confirm':
				error = validation.validateConfirmPassword(this.state.password, value);
				if (error) {
					this.addSingleError(error);
				} else this.removeError('confirm');
				break;

			case 'mobileNumber':
				error = validation.validateMobile(value, this.state.country, countryCodes[this.state.country.toLowerCase()]);
				if (error) {
					this.addSingleError({ mobileNumber: error });
				} else this.removeError('mobileNumber');
				break;

			default:
				error = validation.validateEntry(name, value);
				if (error) {
					this.addSingleError({ [name]: error });
				} else {
					this.removeError(name);
				}
				break;
		}
	};

	addSingleError = error => {
		this.setState(prevState => ({
			errors: {
				...prevState.errors,
				...error,
			}
		}))
	};

	removeError = name => {
		const { errors } = this.state;
		let newErrors = { ...errors };
		if (newErrors.hasOwnProperty(name)) {
			delete newErrors[name];
		}
		this.setState({ errors: newErrors });
	};

	onSubmit = async () => {
		const {
			errors,
			showForm,
			response,
			fom,
			...formValues
		} = this.state;

		const newErrors = validation.validateSignupForm({ ...formValues, fom: fom !== '' ? fom : null });

		if (!newErrors) this.register();

		this.setState({ errors: newErrors || {} });
	};

	register = async () => {
		const {
			errors,
			showForm,
			response,
			birthday,
			confirm,
			loading,
			...otherFormValues
		} = this.state;

		// remove empty array element

		const user = {
			birthday,
			...otherFormValues,
		}

		this.setState({ loading: true })

		// Returns true if successful
		try {
			const result = await authActions.registerUser(user);
			let emailError = null;
			// handle email error
			if ('errorCode' in result) {
				const { errorCode, message } = result;
				if ('AUTHENTICATION_INVALID_EMAIL' === errorCode) {
					// assign email error to appropriate field
					emailError = { email: [message] };
				}
			}

			const { status, errors } = result;

			if (errors || emailError) {
				let errorObj = {}
				if (errors) {
					// .. errors are expected to be contained within an array
					errorObj = Object.keys(errors).reduce((acc, e) => {
						acc[e] = [];
						acc[e].push(errors[e]);
						return acc;
					}, {});
				}

				if (emailError) {
					errorObj = { ...errorObj, ...emailError };
				}
				// assign error to field based on results
				this.addSingleError(errorObj);

			} else if (status && status === 'success') {
				this.setState({
					showForm: false,
					response: result.message
				})
			}
		} catch (error) {
			console.info('DEBUG: SignupPage -> register -> error', error);
		}

		this.setState({ loading: false });

	}

	render() {
		const {
			firstName,
			lastName,
			email,
			mobileNumber,
			birthday,
			postcode,
			fom,
			password,
			confirm,
			privacyPolicy,
			agreeToTerms,
			errors,
			showForm,
			response,
			loading,
			country
		} = this.state;

		// console.log(errors);
		return (
			showForm ?
				<SignupForm
					onChange={this.onChange}
					onSubmit={this.onSubmit}
					handleBirthday={this.handleBirthday}
					firstName={firstName}
					lastName={lastName}
					email={email}
					mobileNumber={mobileNumber}
					birthday={birthday}
					postcode={postcode}
					fom={fom}
					password={password}
					confirm={confirm}
					privacyPolicy={privacyPolicy}
					agreeToTerms={agreeToTerms}
					errors={errors}
					loading={loading}
					country={country}
				/>
				:
				<ConfirmationMessage
					email={email}
					message={response}
					title="Check Your Email!"
				/>

		)
	}
}

export default SignupPage;