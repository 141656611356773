export const parseUrlErrorCode = location => {
  const params = new URLSearchParams(location.search);
  const _errorCode = params.get('errorcode');
  if (_errorCode) {
    const error = window.atob(_errorCode);
    const parsedError = JSON.parse(error);
    console.log(parsedError);
    const { message } = parsedError;

    return message;
  }
};
