import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


import NewPasswordForm from '../components/NewPasswordForm';
import * as validation from '../utils/validationHelpers';
import { resetPassword } from '../actions/authActions';
import ConfirmationMessage from '../components/ConfirmationMessage';



class NewPasswordPage extends Component {
  state = {
    password: '',
    confirm: '',
    showForm: true,
    response: '',
    errors: { password: [], confirmPassword: [] },
    generalError: '',
    loading: false
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    }, () => {
      this.validatePassword();
    })
   
  }

  validatePassword = () => {
    const {
      password,
      confirm
    } = this.state;
    
    const isValid = validation.validatePassword(password);
    const isSame = validation.validateConfirmPassword(password, confirm);
    
    let passwordErrors = [];
    let confirmErrors = [];

    if(isValid !== undefined) {
      passwordErrors = isValid.password;
    } else if(isSame !== undefined) {
      confirmErrors = isSame.confirm
    }
    this.setState({ errors: { password: passwordErrors, confirmPassword: confirmErrors } });
  }

  render() {
    const { password, confirm, errors, showForm, generalError, loading } = this.state;
    return showForm ? (
      <NewPasswordForm 
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        password={password}
        confirm={confirm}
        errors={errors}
        generalError={generalError}
        loading={loading}
      />
    ) : (
      <ConfirmationMessage
        message="You can now log into your account with your new password."
        title="Password Set Successfully!"
      />
    )
  }

  onSubmit = async () => {
    await this.validatePassword();

    if(this.state.errors.password.length === 0 && this.state.errors.confirmPassword.length === 0) {
      this.resetPassword();
    }
  };

  resetPassword = async () => {
		this.setState({ loading: true });
    const {
      password
    } = this.state;
    let result = false;

		const { location } = this.props;
    const params = new URLSearchParams(location.search);
		const mode = params.get('mode')
		const actionCode = params.get('oobCode');
		const continueUrl = params.get('continueUrl');
    const lang = params.get('lang') || 'en';

    try {
			result = await resetPassword({
				mode, 
				actionCode,
				continueUrl,
        lang,
        newPassword: password
			});
		} catch (error) {
      this.setState({
        generalError: error.response.data.message
      })
		}

    if (result) {

      const { status } = result;
      if (status === 200) {
        this.setState({
          showForm: false,
          response: result.data.message
        })
      }
    }
		this.setState({ loading: false });
  }
}

export default withRouter(NewPasswordPage);
