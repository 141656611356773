import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { returnFirstError } from '../../utils/validationHelpers';
import { isArray } from 'util';

const styles = theme => ({
  checkboxRoot: {
    color: "#b9b9b9"
  },
  checkboxChecked: {
    color: "#0079c1"
  },
  formControlLabel: {
    userSelect: "none",
    color: "#b9b9b9",
    fontWeight: 300,
    fontSize: "1rem"
  }
});

const MerloCheckbox = props => {
  const { classes, onChange, containerStyle, checked, name, label, error } = props;

  let hasError = false;
  if((isArray(error) && error.length > 0) || (!isArray(error) && Boolean(error))) {
    hasError = true;
  }

  const errorMsg = returnFirstError(error);

  return (
    <React.Fragment>
        <FormControl required error={hasError}>
            <FormControlLabel
                label={label}
                style={containerStyle}
                classes={{
                label: classes.formControlLabel
                }}
                control={
                <Checkbox
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    color="default"
                    classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checkboxChecked
                    }}
                />
                }
            />
            <FormHelperText style={{ margin: 0 }}>{hasError ? errorMsg : ''}</FormHelperText>
        </FormControl>
    </React.Fragment>
  );
};

MerloCheckbox.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  containerStyle: PropTypes.object
};

export default withStyles(styles)(MerloCheckbox);
