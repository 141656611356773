import React, { Component } from 'react';

import * as authActions from '../actions/authActions';
import * as validation from '../utils/validationHelpers';

import ResetForm from '../components/ResetForm';
import ConfirmationMessage from '../components/ConfirmationMessage';

class ResetPasswordPage extends Component {
  state = {
    showForm: true,
    email: '',
    emailError: [],
    response: '',
    loading: false
  };

  onChange = e => {
    const { name, value } = e.target;

    let emailError = validation.validateEmailFlat(value);
    this.setState({
      [name]: value,
      emailError: emailError || []
    });
  };

  onSubmit = async () => {
    this.setState({ loading: true })

    const { email, emailError } = this.state;

    if (!email) {
      this.setState({ emailError: ['This field is required.'] });
    };

    if (email && emailError.length === 0) {
      await this.requestReset();
    }
    this.setState({ loading: false })
  };

  requestReset = async () => {
    try {
      const { email } = this.state;
      const result = await authActions.requestResetPassword(email);

      if (result.data.status === 'success') {
        this.setState({
          showForm: false,
          response: result.data.message
        });
      } 

      // catch error here
      
    } catch (error) {

      this.setState({
        emailError: [error.message],
        loading: false
      });
    }
  };

  render() {
    const { email, emailError, showForm, response, loading } = this.state;
    return (
      showForm ? (
        <ResetForm
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          email={email}
          error={emailError}
          loading={loading}
        />
      ) : (
          <ConfirmationMessage
            email={email}
            title="Check your email!"
            message={response}
          />
        )
    )
  }
}

export default ResetPasswordPage;
